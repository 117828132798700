import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { Title, Button, Section, Box, Text, Input } from "../components/Core";

import PageWrapper from "../components/PageWrapper";
import { device } from "../utils";

import { useForm } from 'react-hook-form';
  
const FormStyled = styled.form``;

const WidgetWrapper = styled(Box)`
  border-radius: 10px;
  background-color: #f7f7fb;
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  @media ${device.lg} {
    padding-left: 140px;
    padding-right: 150px;
  }
  @media ${device.xl} {
    padding-left: 150px;
    padding-right: 150px;
  }
`;

const GATEWAY_URL = 'https://email.nightshift.io';
const required = "This field is required";

const Contact2 = () => {

const onSubmit = async data => {
  try {
    await fetch(GATEWAY_URL, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        'Access-Control-Allow-Origin': '*',
      }
    });
    setSubmitted(true);
    reset();
  } catch (error) {
    setError(
      "submit",
      "submitError",
      `Oops! There seems to be an issue! ${error.message}`
    );
  }
};

const [submitted, setSubmitted] = useState(false);
const {
  register,
  handleSubmit,
  setError,
  errors,
  reset,
  formState: { isSubmitting }
} = useForm();

const showSubmitError = msg => <p className="msg-error">{msg}</p>;

const showThankYou = (
  <div className="msg-confirm">
    <p>Thank you. Your message was sent.</p>
    <Button onClick={() => setSubmitted(false)} width="100%" type="submit" borderRadius={10}>
      Send another message
    </Button>
  </div>
);

const showContactForm = (
  <FormStyled name="contact" method="post" onSubmit={handleSubmit(onSubmit)}>
  <input type="hidden" name="form-name" value="contact" />
    <Box mb={4}>
      <Title
        variant="card"
        fontSize="18px"
        as="label"
        htmlFor="nameput"
      >
        Your name
      </Title>
      <Input
        type="text"
        placeholder="i.e. Alan Turing"
        id="nameput"
        name="nameput"
        ref={register({ required: true })}
        disabled={isSubmitting}
      />
      {errors.name && <div className="msg-error">{errors.nameput.message}</div>}
    </Box>
    <Box mb={4}>
      <Title
        variant="card"
        fontSize="18px"
        as="label"
        htmlFor="emailput"
      >
        Email address
      </Title>
      <Input
        type="email"
        placeholder="i.e. alan@turing.com"
        id="emailput"
        name="emailput"
        ref={register({ required: "A valid email is required." })}
        disabled={isSubmitting}
      />
      {errors.email && (
        <div className="msg-error">{errors.emailput.message}</div>
      )}
    </Box>

    <Box mb={4}>
      <Title
        variant="card"
        fontSize="18px"
        as="label"
        htmlFor="serviceput"
      >
        Service
      </Title>
      <Input
        type="text"
        placeholder="i.e. Let's go build a cloud"
        id="serviceput"
        name="serviceput"
        ref={register}
        disabled={isSubmitting}
      />
    </Box>
    {errors.question && (
      <div className="msg-error">{errors.serviceput.message}</div>
    )}      
    <Box mb={4}>
      <Title
        variant="card"
        fontSize="18px"
        as="label"
        htmlFor="messageput"
      >
        Message
      </Title>
      <Input
        type="text"
        as="textarea"
        placeholder="Write your message here"
        rows={4}
        id="messageput"
        ref={register}
        name="messageput"
        disabled={isSubmitting}
      />
      {errors.question && (
        <div className="msg-error">{errors.messageput.message}</div>
      )}
    </Box>

    <Button disabled={isSubmitting} width="100%" type="submit" borderRadius={10}>
      Send Message
    </Button>
  </FormStyled>
);

  return (
    <>
    <Helmet>
      <title>NightShift | Contact</title>
    </Helmet>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <div className="banner-content">
                  <Title variant="hero">Start your Transformation</Title>
                  <Text>
                    We look forward to the opportunity to deliver value.
                  </Text>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center mt-5 pt-lg-5">
              <Col xl="10">
                <WidgetWrapper>
                  <Row>
                    <Col md="4" sm="6">
                      <Box className="mb-5">
                        <Title variant="card" fontSize="24px">
                          By Phone
                        </Title>
                        <Text>617-934-8285</Text>
                      </Box>
                    </Col>
                    <Col md="4" sm="6">
                      <Box className="mb-5">
                        <Title variant="card" fontSize="24px">
                          By Email
                        </Title>
                        <Text>contact@nightshift.io</Text>
                      </Box>
                    </Col>
                    <Col md="4" sm="6">
                      <Box className="mb-5">
                        <Title variant="card" fontSize="24px">
                          By Mail
                        </Title>
                        <Text>68 Harrison Ave,</Text>
                        <Text>Boston, MA, 02111</Text>
                      </Box>
                    </Col>
                  </Row>
                </WidgetWrapper>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={6} className="order-lg-1 pt-5 mt-4">
                <div>
                  <Title
                    variant="card"
                    fontSize="24px"
                    className="mb-5 text-center"
                  >
                    or, Send us a message
                  </Title>
                  {errors && errors.submit && showSubmitError(errors.submit.message)}
                  {submitted ? showThankYou : showContactForm}
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Contact2;
